import PropTypes from 'prop-types';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import Anchor from '../../ui/Anchor';
import Text from '../../ui/Text';
import { AuthorMetaWrap } from './author-meta.style';

const AuthorMeta = ({ slug, name, imageSrc, ...restProps }) => {
  return (
    <AuthorMetaWrap {...restProps}>
      <Anchor path={`/blog/autor/${slug}`}>
        <FaUserCircle />
        <Text as="span">{name}</Text>
      </Anchor>
    </AuthorMetaWrap>
  );
};

AuthorMeta.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
};

export default AuthorMeta;
