import React from 'react';
import Text from '../../ui/Text';
import Anchor from '../../ui/Anchor';
import { BlogMetaWrap } from './blog-meta.style';

const BlogMeta = ({ icon, text, path, ...restProps }) => {
  return (
    <BlogMetaWrap {...restProps}>
      <Anchor path={path}>
        {icon}
        <Text as="span">{text}</Text>
      </Anchor>
    </BlogMetaWrap>
  );
};

export default BlogMeta;
