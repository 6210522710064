import React from 'react';
import Anchor from '../../ui/Anchor';
import { CategroiesWrap } from './categories.style';

const Categories = ({ categories, ...restProps }) => {
  return (
    <CategroiesWrap {...restProps}>
      {categories.map(cat => (
        <Anchor path={`/blog/categoria/${cat.slug}`} key={cat.slug}>
          {cat.title}
        </Anchor>
      ))}
    </CategroiesWrap>
  );
};

export default Categories;
